import style from './404.module.css'

import { getLayout } from '../components/sg/Layout'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import Banner from '../components/Banner'
import Link from 'next/link'

export default function FourOFour () {
  const [t] = useTranslation('main')
  const c = classNames.bind(style)

  return (
    <>
      <Header showLogo />
      <div className={c('notfound')}>
        <p>
          {t('notfound-title', 'Sorry, this page doesn`t exist.')}
          <br /><br />
          <Link href='/'>{t('notfound-link', 'Return home')}</Link>
        </p>
      </div>
      <Banner />
    </>
  )
}

FourOFour.getLayout = getLayout
